import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import { getRoleID } from "../../Token"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import config from "config/config"
import DashboardCard from "./DashboardCard"
import constants from "config/constants"
import { DashboardCountApi } from "apis/DashboardCountApi"; 

const Dashboard = props => {
  const [UserCount, setUserCount] = useState(0)
  const [SessionCount, setSessionCount] = useState(0)
  const [pastSessionCount, setPastSessionCount] = useState(0)
  const [upcomingSessionCount, setUpcomingSessionCount] = useState(0)
  const [todaySessionCount, setTodaySessionCount] = useState(0)
  const [cancelledSessionCount, setCancelledSessionCount] = useState(0)
  const [weeklySessionCount, setWeeklySessionCount] = useState(0)
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {getAllDashboardCount(activeData);}, [])

  const getAllDashboardCount = (data) => {
    DashboardCountApi.getDashboardCount(data)
        .then((res) => {
            setActiveData({
                page: activeData.page,
                totalPage: res?.data?.data?.count,
                search: activeData.search,
                limit: activeData.limit,
            });
            setUserCount(res.data.UserCount);
            setSessionCount(res.data.SessionCount);
            setUpcomingSessionCount(res.data.upcomingSessionCount);
            setTodaySessionCount(res.data.todaySessionCount);
            setPastSessionCount(res.data.pastSessionCount);
            setCancelledSessionCount(res.data.cancelledSessionCount);
            setWeeklySessionCount(res.data.weeklySessionCount);
        })
        .catch((err) => {
            console.log(err);
        });
  };



  //meta title
  document.title = props.t("Dashboard") + " | " + props.t("LearnLive")
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {getRoleID() === constants.ROLE.SUPERADMIN && (
            <>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Total Sessions")}
                    path="/all-sessions"
                    totalCount={SessionCount}
                    iconName="fa-regular fa-building font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Upcoming Sessions")}
                    path="/"
                    totalCount={upcomingSessionCount}
                    iconName="fa-solid fa-timeline font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Today's Sessions")}
                    path="/"
                    totalCount={todaySessionCount}
                    iconName="fa-solid fa-building-columns font-size-24"
                  />
                </Col>
              </Row>
              <Row>
              <Col sm={4}>
                  <DashboardCard
                    title={props.t("Completed Sessions")}
                    path="/"
                    totalCount={pastSessionCount}
                    iconName="fa-solid fa-building-columns font-size-24"
                  />
                </Col>                
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Cancelled Sessions")}
                    path="/"
                    totalCount={cancelledSessionCount}
                    iconName="bx bxs-user font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Total Users")}
                    path="/"
                    totalCount={UserCount}
                    iconName="bx bxs-user font-size-24"
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Weekly Session Count")}
                    path="/"
                    totalCount={weeklySessionCount}
                    iconName="bx bxs-user font-size-24"
                  />
                </Col>
              </Row>
              
            </>
          )}

          
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
