import config from "../config/config"
import axios from "axios"
import { getToken } from "../Token"
import * as url from "../helpers/url_helper"

const token = getToken()
export const DashboardCountApi = {
  getDashboardCount: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_DASHBOARD_COUNT}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
}