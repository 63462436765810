import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";


const token = getToken();
export const ReportApi = {
    getLoginHostory: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.LOGIN_HOSTORY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },

    getLoginHistoryWithoutLimit: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.LOGIN_HISTORY_WITHOUT_LIMIT}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    }
}