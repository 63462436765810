import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import UpdatePassword from "pages/Authentication/UpdatePassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ProfileRoles from "../pages/Profile/Role/Roles"
import ProfileAddRoles from "../pages/Profile/Role/AddRoles"
import ProfileEditRoles from "../pages/Profile/Role/EditRoles"
import Permission from "../pages/Profile/Role/Permission"
import UpdateProfile from "../pages/Profile/UserProfile/UpdateProfile"
import ChangePassword from "../pages/Profile/UserProfile/ChangePassword"
import PageContent from "pages/Master/PageContent/PageContent"
import EditPageContent from "pages/Master/PageContent/EditPageContent"
import SystemSetting from "pages/Setting/SystemSetting/SystemSetting"
import LoginHistory from "pages/Reports/LoginHistory"
import AppVersion from "pages/Master/AppVersion/AppVersion";
import Category from "pages/Master/Category/Category"
import Language from "pages/Master/Language/Language"
import AllSessions from "pages/Sessions/AllSessions/AllSessions"
import SessionDetails from "pages/Sessions/AllSessions/SessionDetails"
import ActivityTypes from "pages/Master/Activity Types/ActivityTypes"
import CMS from "../pages/Setting/CMS/CMS";
import EditCMS from "pages/Setting/CMS/EditCMS";
import TimeZone from "pages/Master/Time Zone/TimeZone";
import SessionType from "pages/Master/Session Type/SessionType";

import Story from "pages/Story/Story"
import EditStory from "pages/Story/EditStory"
import AddStory from "pages/Story/AddStory"



const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Profile
  { path: "/roles", component: ProfileRoles },
  { path: "/add-role", component: ProfileAddRoles },
  { path: "/edit-role/:role_id", component: ProfileEditRoles },
  { path: "/permission/:role_id", component: Permission },
  { path: "/page-contents", component: PageContent },
  { path: "/edit-page-content/:page_content_id", component: EditPageContent },
  { path: "/system-settings", component: SystemSetting },

  // Non Module Route
  { path: "/update-profile", component: UpdateProfile },
  { path: "/change-password", component: ChangePassword },

  { path: "/app-versions", component: AppVersion },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //master
  { path: "/categories", component: Category },
  { path: "/languages", component: Language },

  // CMS
  { path: "/cms", component: CMS },
  { path: "/edit-cms-page/:cms_id", component: EditCMS },

  // TIME ZONE
  { path: "/timezones", component: TimeZone },

  // SESSION TYPE
  { path: "/session-types", component: SessionType },

  //sessions
  { path: "/all-sessions", component: AllSessions },
  { path: "/session-details/:session_id", component: SessionDetails },

  // Activity Types
  { path: "/activity-types", component: ActivityTypes },

  // Stories
  { path: "/stories", component: Story },
  { path: "/edit-story/:story_id", component: EditStory },
  { path: "/add-story", component: AddStory },




  // { path: "/today-sessions", component: TodaySessions },
  // { path: "/upcoming-sessions", component: UpcomingSessions },
  // { path: "/completed-sessions", component: CompletedSessions },
  // { path: "/cancelled-sessions", component: CancelledSessions }
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/update-password", component: UpdatePassword },
  { path: "/register", component: Register },

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
