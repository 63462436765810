let constants = {
  STATUS: {
    1: "Active",
    0: "Deactive",
  },
  ROLE: {
    SUPERADMIN: 1,
    CONSULTANT: 2,
    STUDENT: 3,
  },
  USER_STATUS : {
    Pending : "Pending",
    Approved : "Approved",
    Rejected : "Rejected"
  },

  SESSION_STATUS : {
    Pending : "Pending",
    Started : "Started",
    Completed : "Completed",
    Cancelled : "Cancelled"
  },

  ACTIVITY_TYPE : {
    SELECT: "= SELECT =",
    MENTORING : "MENTORING",
    GROUP_DISCUSSION : "GROUP_DISCUSSION",
    INTERVIEW : "INTERVIEW",
    DEBATE : "DEBATE",
    STANDUP : "STANDUP",
    OPENMIC : "OPENMIC"
  },

  PAGE_TYPES:{
    Privacy_Policy: "Privacy Policy",
    About_Us: "About Us",
    Terms_Of_Use: "Terms of Use",
    Contact_Us: "Contact Us",
  },

  DEVICE_TYPE:{
    android: "Android",
    ios: "iOS",
  },

  SETTING_TAB: {
    GENERAL: "GENERAL",
    SMTP: "SMTP"
  },

  FORM_CONTROL_TYPE:{
    SELECT: "SELECT",
    INPUT: "INPUT",
    TEXTAREA: "TEXTAREA"
  },

  IS_REQUIRED: {
    1: "YES",
    0: "NO",
  },

}

export default constants
