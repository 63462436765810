// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {Col} from "reactstrap";
//import components
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { StoryApi } from "apis/StoryApi";
import { withTranslation } from "react-i18next";
import config from "config/config";
import Swal from "sweetalert2";

function Story(props) {
    const [loading, setLoading] = useState(false);
    const [stories, setStories] = useState([]);
    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT,
    });
    useEffect(() => {
        getStories(activeData);
    }, []);

    const getStories = (data) => {
        // setLoading(true);
        StoryApi.getStories(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                let sr_no = 1;
                res?.data?.data?.rows?.forEach((v, i) => {
                    data[i] = {
                        story_id: sr_no++,
                        title: v.title,
                        subtitle: v.subtitle,
                        // status: v.status == 1 ? (
                        //     <>
                        //         <span style={{ color: "green" }}>{props.t("Active")}</span>
                        //     </>
                        // ) : (
                        //     <>
                        //         <span style={{ color: "red" }}>{props.t("Deactive")}</span>
                        //     </>
                        // ),
                        action: (
                            <>
                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-story/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>

                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setStories(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.story_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title"),
            selector: row => row.title,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Subtitle"),
            selector: row => row.subtitle,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Status"),
        //     selector: row => row.status,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];
    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getStories(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getStories(data)
    }

    const onDelete = (story_id) => {
        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let storyId = { "story_id": story_id };
                StoryApi.deleteStory(storyId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        getStories(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    document.title = "Stories | LearnLive";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Setting")} breadcrumbItem={props.t("Stories")} />
            </div>

            {/* <Link style={{ marginRight: "15px" }} to={`/add-story`} > <i className="btn btn-primary" />
                {props.t("Add Story")}
            </Link> */}

    <Col>
        <div style={{margin:'12px'}} className="d-flex">
            <Link to={`/add-story`} className="btn btn-primary">
                {props.t("Add Story")}
            </Link>
        </div>
    </Col>

            <div className="container-fluid">
                <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={stories}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                />
            </div>
        </div>
    );
}
Story.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default withTranslation()(Story);