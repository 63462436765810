// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';

import { ActivityTypeApi } from "apis/ActivityTypeApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import Select from "react-select";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function ActivityType(props) {
    const history = useHistory();
    const [activityTypeLise, setActivityTypeList] = useState([]);
    const [activitytypes, setActivityType] = useState([]);
    const [activityTypeId, setActivityTypeId] = useState('');
    const [loading, setLoading] = useState(false);
    const [defaultType, setDefaultType] = useState();

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getActivityType(activeData);
        setDefaultType({
            value : constants.ACTIVITY_TYPE.SELECT,
            label : constants.ACTIVITY_TYPE.SELECT
        });
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setActivityType();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.activity_type_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title"),
            selector: row => row.title,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Type"),
            selector: row => row.type,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Action"),
        //     selector: row => row.action,
        //     sort: "asc",
        //     width: 200,
        // }
    ];

    const getActivityType = (data) => {
        setLoading(true);
        ActivityTypeApi.getActivityTypes(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        activity_type_id: sr_no,
                        title: v.title,
                        type: v.type,
                        // action: (
                        //     <>
                        //         <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                        //         <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                        //     </>
                        // ),
                    };
                });
                setActivityTypeList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const onDelete = (id) => {
    //     Swal.fire({
    //         title: props.t("Are_you_sure"),
    //         text: props.t("Able_to_revert_this"),
    //         icon: props.t("Warning"),
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#f46a6a",
    //         confirmButtonText: props.t("Yes_delete_it"),
    //         cancelButtonText: props.t("Cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             let activityTypeId = { "activity_type_id": id };
    //             ActivityTypeApi.deleteActivityType(activityTypeId)
    //                 .then((res) => {
    //                     Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
    //                     setActivityTypeId();
    //                     formClear()
    //                     getActivityType(activeData)
    //                 })
    //                 .catch((err) => {
    //                     console.log(err);
    //                 });
    //         }
    //     });
    // };

    // const onEdit = (id) => {
    //     let activityTypeId = { "activity_type_id": id };
    //     setActivityTypeId(id);
    //     ActivityTypeApi.getActivityType(activityTypeId)
    //         .then((res) => {
    //             setActivityType(res.data.data);
    //             setDefaultType({
    //                 value : res.data?.data?.type,
    //                 label : constants.ACTIVITY_TYPE[res.data?.data?.type]
                    
    //             });
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };
    
    // Form validation 
    // const validationType = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         activity_type_id: activitytypes ? activitytypes.id : "",
    //         title: activitytypes ? activitytypes.title : true,
    //         type: activitytypes ? activitytypes.type : true,
    //     },
    //     validationSchema: Yup.object().shape({
    //         type: Yup.string().required(
    //             props.t("Required-Message")
    //         )
    //     }),
    //     onSubmit: (values) => {
    //         if (activityTypeId) {
    //             values.activity_type_id = activityTypeId;
    //             ActivityTypeApi.updateActivityType(values)
    //                 .then(res => {
    //                     if (res.data.success) {
    //                         Swal.fire({
    //                             text: res.data.message,
    //                             icon: 'success',
    //                             imageAlt: 'success image',
    //                         }).then((result) => {
    //                             if (result.isConfirmed) {
    //                                 history.push('/activity-types');
    //                                 formClear();
    //                                 getActivityType(activeData);
    //                                 setDefaultType({
    //                                     value : constants.ACTIVITY_TYPE[1],
    //                                     label : constants.ACTIVITY_TYPE[1]
    //                                 });
    //                                 setActivityTypeId();
    //                             }
    //                         });
    //                     } else {
    //                         toastr.error(res.message, '');
    //                     }
    //                 }).catch(function (error) {
    //                     if (error?.response?.data?.message) {
    //                         toastr.error(error?.response?.data?.message, '');
    //                     }
    //                     else {
    //                         toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
    //                     }
    //                 });
    //         }
            // else {
            //     ActivityTypeApi.createLanguage(values)
            //         .then(res => {
            //             if (res.data.success) {
            //                 Swal.fire({
            //                     text: res.data.message,
            //                     icon: 'success',
            //                     imageAlt: 'success image',
            //                 }).then((result) => {
            //                     if (result?.isConfirmed) {
            //                         history.push('/activity-types');
            //                         formClear();
            //                         getActivityType(activeData);
            //                         setDefaultType({
            //                             value : constants.STATUS[1],
            //                             label : constants.STATUS[1]
            //                         });
            //                     }
            //                 });
            //             } else {
            //                 toastr.error(res?.data?.message, '');
            //             }
            //         }).catch(function (error) {
            //             if (error?.response?.data?.message) {
            //                 toastr.error(error?.response?.data?.message, '');
            //             }
            //             else {
            //                 toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
            //             }
            //         });
            // }

    //     }
    // });

    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getActivityType(data)
    }

    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getActivityType(data)
    }

    const handleChangeSearch = () => {
        const data = { page: 1, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getActivityType(data)
         }
      const onSearch = (e) => {
           setActiveData({ ...activeData, search: e.target.value })
      }

    //meta title
    document.title = props.t("activitytypes") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("activitytypes")} />
            </div>


            <Row>
      <Col>
        <div style={{margin:'12px'}} className="d-flex">
          <Input onChange={onSearch} className="w-25" name="search" placeholder={props.t("Search")} />
          <button onClick={handleChangeSearch} className="btn btn-primary">{props.t("Search")}</button>
        </div>
      </Col>

      </Row>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={activityTypeLise}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>

                                        <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Type")}
                                                        {" "} <span className="text-danger">*</span></Label>
                                                    <Select
                                                        className={
                                                            validationType.touched.type &&
                                                            validationType.errors.type
                                                                ? "bor "
                                                                : "text-dark"
                                                        }
                                                        styles={{
                                                            control: styles => ({
                                                                ...styles,
                                                            }),
                                                        }}
                                                        onChange={e => {
                                                            validationType.setFieldValue("type", e.value)
                                                            setDefaultType(e)
                                                        }}
                                                        value={defaultType || ""}
                                                        onBlur={validationType.handleBlur}
                                                        name="type"
                                                        options={Object.keys(constants.ACTIVITY_TYPE).map(
                                                            value => ({
                                                                value: `${value}`,
                                                                label: `${constants.ACTIVITY_TYPE[value]}`
                                                            })
                                                        )}
                                                        placeholder={
                                                            <div>{props.t("Select_Type")}</div>
                                                        }
                                                    />
                                                    {validationType.touched.type && validationType.errors.type ? (
                                                        <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                            {validationType.errors.type}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
            </Container>
        </div>
    );
}

ActivityType.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(ActivityType);