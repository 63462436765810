// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';

import { SessionTypeApi } from "apis/SessionTypeApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import Select from "react-select";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function SessionType(props) {
    const history = useHistory();
    const [sessionTypeList, setSessionTypeList] = useState([]);
    const [sessionTypes, setSessionTypes] = useState([]);
    const [sessionTypeId, setSessionTypeId] = useState('');
    const [loading, setLoading] = useState(false);
    // const [defaultStatus, setDefaultStatus] = useState();
    const [color, setColor] = useState(null);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getSessionTypes(activeData);
        // setDefaultStatus({
        //     value : constants.STATUS[1],
        //     label : constants.STATUS[1]
        // });
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setSessionTypes();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.session_type_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name"),
            selector: row => row.name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Type"),
        //     selector: row => row.type,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("Color"),
            selector: row => row.color,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Remark"),
            selector: row => row.remark,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getSessionTypes = (data) => {
        setLoading(true);
        SessionTypeApi.getSessionTypes(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        session_type_id: sr_no,
                        name: v.name,
                        type: v.type,
                        color: v.color,
                        remark: v.remark,
                        action: (
                            <>
                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                {/* <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} /> */}
                            </>
                        ),
                    };
                });
                setSessionTypeList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onEdit = (id) => {
        let sessionTypeId = { "session_type_id": id };
        setSessionTypeId(id);
        SessionTypeApi.getSessionType(sessionTypeId)
            .then((res) => {
                setSessionTypes(res.data.data);
                // setDefaultStatus({
                //     value : res.data?.data?.status,
                //     label : constants.STATUS[res.data?.data?.status]
                    
                // });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            session_type_id: sessionTypes ? sessionTypes.id : "",
            name: sessionTypes ? sessionTypes.name : "",
            type: sessionTypes ? sessionTypes.type : "",
            color: sessionTypes ? sessionTypes.color : "",
            remark: sessionTypes ? sessionTypes.remark : ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                props.t("Required-Message")
            ),
            remark: Yup.string().required(
                props.t("Required-Message")
            )
        }),
        onSubmit: (values) => {
            if (sessionTypeId) {
                values.session_type_id = sessionTypeId;
                SessionTypeApi.updateSessionType(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/session-types');
                                    formClear();
                                    getSessionTypes(activeData);
                                    // setDefaultStatus({
                                    //     value : constants.STATUS[1],
                                    //     label : constants.STATUS[1]
                                    // });
                                    setSessionTypeId();
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    });

    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getSessionTypes(data)
    }

    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getSessionTypes(data)
    }

    const handleChangeSearch = () => {
        const data = { page: 1, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getSessionTypes(data)
         }
      const onSearch = (e) => {
           setActiveData({ ...activeData, search: e.target.value })
      }


       console.log(color)
    //meta title
    document.title = props.t("Session Types") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Session Types")} />
            </div>


            <Row>
      <Col>
        <div style={{margin:'12px'}} className="d-flex">
          <Input onChange={onSearch} className="w-25" name="search" placeholder={props.t("Search")} />
          <button onClick={handleChangeSearch} className="btn btn-primary">{props.t("Search")}</button>
        </div>
      </Col>

      </Row>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={sessionTypeList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="name"
                                                    placeholder={props.t("Name")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name || ""}
                                                    invalid={
                                                        validationType.touched.name && validationType.errors.name ? true : false
                                                    }
                                                />
                                                {validationType.touched.name && validationType.errors.name ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Type")}{" "}</Label>
                                                <Input
                                                    name="type"
                                                    placeholder={props.t("Type")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.type || ""}
                                                    readOnly="readOnly"
                                                    disabled={true}
                                                    invalid={
                                                        validationType.touched.type && validationType.errors.type ? true : false
                                                    }
                                                />
                                                {validationType.touched.type && validationType.errors.type ? (
                                                    <FormFeedback type="invalid">{validationType.errors.type}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Row>
                                            <Col sm={5}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Color Code")}{" "}</Label>
                                                    <Input
                                                        name="color"
                                                        placeholder={props.t("Color Code")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.color || ""}
                                                        readOnly="readOnly"
                                                        disabled={true}
                                                        invalid={
                                                            validationType.touched.color && validationType.errors.color ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.color && validationType.errors.color ? (
                                                        <FormFeedback type="invalid">{validationType.errors.color}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm = {3}>
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="example-color-input"
                                                        // className="col-md-2 col-form-label"
                                                    >Color</label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control form-control-color mw-100"
                                                            type="color"
                                                            defaultValue="#556ee6"
                                                            id="example-color-input"
                                                            onChange={e => {
                                                                setColor(e.target.value)
                                                                validationType.setFieldValue("color", e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Remark")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="remark"
                                                    placeholder={props.t("Remark")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.remark || ""}
                                                    invalid={
                                                        validationType.touched.remark && validationType.errors.remark ? true : false
                                                    }
                                                />
                                                {validationType.touched.remark && validationType.errors.remark ? (
                                                    <FormFeedback type="invalid">{validationType.errors.remark}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

SessionType.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(SessionType);