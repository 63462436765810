// LOGIN
export const POST_ADMIN_LOGIN = "/api/admin/sign-in";

// PROFILE roles
export const POST_CREATE_ROLE = "/api/admin/create-role";
export const POST_UPDATE_ROLE = "/api/admin/update-role";
export const POST_FETCH_ROLE = "/api/admin/get-role";
export const POST_FETCH_ALL_ROLES = "/api/admin/get-roles";

//Role fetch-role-modules
export const POST_FETCH_MODULES = "/api/admin/get-role-modules";
export const POST_FETCH_ALL_MODULES = "/api/admin/get-all-modules";
export const POST_FETCH_MODULES_BY_ROLE = "/api/admin/get-modules-by-role";

//Permission
export const SAVE_UPDATE_PERMISSION = "/api/admin/add-update-permissions";

// System Setting
export const POST_CREATE_SYSTEM_SETTING = "/api/admin/create-system-setting";
export const POST_FETCH_ALL_SYSTEM_SETTINGS = "/api/admin/get-system-settings";
export const POST_UPDATE_SYSTEM_SETTING = "/api/admin/update-system-setting";
export const POST_FETCH_SYSTEM_SETTING = "/api/admin/get-system-setting-by-id";
export const POST_DELETE_SYSTEM_SETTING = "/api/admin/delete-system-setting";

//profile
export const POST_UPDATE_USER_LANGUAGE = "/api/admin/update-user-language";
export const POST_UPDATE_USER_PROFILE = "/api/admin/update-profile";
export const POST_UPDATE_USER_PASSWORD = "/api/admin/change-password";
export const POST_GET_USER_PROFILE = "/api/admin/get-profile-detail";

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";
export const GET_EARNING_DATA = "/earning-charts-data";
export const GET_PRODUCT_COMMENTS = "/comments-product";
export const ON_LIKNE_COMMENT = "/comments-product-action";
export const ON_ADD_REPLY = "/comments-product-add-reply";
export const ON_ADD_COMMENT = "/comments-product-add-comment";

// Report
export const LOGIN_HOSTORY = "/api/admin/fetch-login-histories";
export const LOGIN_HISTORY_WITHOUT_LIMIT = "/api/admin/fetch-login-histories-without-limit";

//CMSS
export const POST_FETCH_ALL_CMS = "/api/admin/get-cmspage";
export const POST_FETCH_CMS = "/api/admin/get-cms";
export const POST_DELETE_CMS = "/api/admin/delete-cms";
export const POST_UPDATE_CMS = "/api/admin/update-cms";

// Master
// Country
export const POST_FETCH_ALL_COUNTRIES = "/api/common/countries";
export const POST_FETCH_COUNTRIES = "/api/superadmin/countries";
export const POST_FETCH_COUNTRY = "/api/superadmin/country-details";
export const POST_CREATE_COUNTRY = "/api/superadmin/create-country";
export const POST_UPDATE_COUNTRY = "/api/superadmin/update-country";
export const POST_DELETE_COUNTRY = "/api/superadmin/delete-country";

// States
export const POST_FETCH_ALL_STATES = "/api/common/states";
export const POST_FETCH_STATES = "/api/superadmin/states";
export const POST_FETCH_STATE = "/api/superadmin/state-details";
export const POST_CREATE_STATE = "/api/superadmin/create-state";
export const POST_UPDATE_STATE = "/api/superadmin/update-state";
export const POST_DELETE_STATE = "/api/superadmin/delete-state";

// City
export const POST_FETCH_ALL_CITIES = "/api/common/cities";
export const POST_FETCH_CITIES = "/api/superadmin/cities";
export const POST_FETCH_CITY = "/api/superadmin/city-details";
export const POST_CREATE_CITY = "/api/superadmin/create-city";
export const POST_UPDATE_CITY = "/api/superadmin/update-city";
export const POST_DELETE_CITY = "/api/superadmin/delete-city";

// Page Content
export const POST_FETCH_ALL_PAGE_CONTENT = "/api/superadmin/page-contents";
export const POST_FETCH_PAGE_CONTENT = "/api/superadmin/page-content";
export const POST_CREATE_PAGE_CONTENT = "/api/superadmin/create-pagecontent";
export const POST_UPDATE_PAGE_CONTENT = "/api/superadmin/update-pagecontent";
export const POST_DELETE_PAGE_CONTENT = "/api/superadmin/delete-pagecontent";

// Sports
export const POST_FETCH_SPORTS = "/api/superadmin/get-sports";
export const POST_FETCH_SPORT = "/api/superadmin/get-sport";
export const POST_CREATE_SPORT = "/api/superadmin/create-sport";
export const POST_UPDATE_SPORT = "/api/superadmin/update-sport";
export const POST_DELETE_SPORT = "/api/superadmin/delete-sport";

// Academics
export const POST_FETCH_ACADEMICS = "/api/superadmin/get-academics";
export const POST_FETCH_ACADEMIC = "/api/superadmin/get-academic";
export const POST_CREATE_ACADEMIC = "/api/superadmin/create-academic";
export const POST_UPDATE_ACADEMIC = "/api/superadmin/update-academic";
export const POST_DELETE_ACADEMIC = "/api/superadmin/delete-academic";

// Curriculars
export const POST_FETCH_CURRICULARS = "/api/superadmin/get-curriculars";
export const POST_FETCH_CURRICULAR = "/api/superadmin/get-curricular";
export const POST_CREATE_CURRICULAR = "/api/superadmin/create-curricular";
export const POST_UPDATE_CURRICULAR = "/api/superadmin/update-curricular";
export const POST_DELETE_CURRICULAR = "/api/superadmin/delete-curricular";

// Focus Areas
export const POST_FETCH_FOCUS_AREAS = "/api/superadmin/get-focus-areas";
export const POST_FETCH_FOCUS_AREA = "/api/superadmin/get-focus-area";
export const POST_CREATE_FOCUS_AREA = "/api/superadmin/create-focus-area";
export const POST_UPDATE_FOCUS_AREA = "/api/superadmin/update-focus-area";
export const POST_DELETE_FOCUS_AREA = "/api/superadmin/delete-focus-area";

// Grades
export const POST_FETCH_GRADES = "/api/superadmin/get-grades";
export const POST_FETCH_GRADE = "/api/superadmin/get-grade";
export const POST_CREATE_GRADE = "/api/superadmin/create-grade";
export const POST_UPDATE_GRADE = "/api/superadmin/update-grade";
export const POST_DELETE_GRADE = "/api/superadmin/delete-grade";

// Services
export const POST_FETCH_SERVICES = "/api/superadmin/get-services";
export const POST_FETCH_SERVICE = "/api/superadmin/get-service";
export const POST_CREATE_SERVICE = "/api/superadmin/create-service";
export const POST_UPDATE_SERVICE = "/api/superadmin/update-service";
export const POST_DELETE_SERVICE = "/api/superadmin/delete-service";

// Specialities
export const POST_FETCH_SPECIALITIES = "/api/superadmin/get-specialities";
export const POST_FETCH_SPECIALITY = "/api/superadmin/get-speciality";
export const POST_CREATE_SPECIALITY = "/api/superadmin/create-speciality";
export const POST_UPDATE_SPECIALITY = "/api/superadmin/update-speciality";
export const POST_DELETE_SPECIALITY = "/api/superadmin/delete-speciality";

// Badges
export const POST_FETCH_BADGES = "/api/superadmin/get-badges";
export const POST_FETCH_BADGE = "/api/superadmin/get-badge";
export const POST_CREATE_BADGE = "/api/superadmin/create-badge";
export const POST_UPDATE_BADGE = "/api/superadmin/update-badge";
export const POST_DELETE_BADGE = "/api/superadmin/delete-badge";

// Proficiencies
export const POST_FETCH_PROFICIENCIES = "/api/superadmin/get-proficiencies";
export const POST_FETCH_PROFICIENCY = "/api/superadmin/get-proficiency";
export const POST_CREATE_PROFICIENCY = "/api/superadmin/create-proficiency";
export const POST_UPDATE_PROFICIENCY = "/api/superadmin/update-proficiency";
export const POST_DELETE_PROFICIENCY = "/api/superadmin/delete-proficiency";

// Dream Colleges
export const POST_FETCH_DREAM_COLLEGES = "/api/superadmin/get-dream-colleges";
export const POST_FETCH_DREAM_COLLEGE = "/api/superadmin/get-dream-college";
export const POST_CREATE_DREAM_COLLEGE = "/api/superadmin/create-dream-college";
export const POST_UPDATE_DREAM_COLLEGE = "/api/superadmin/update-dream-college";
export const POST_DELETE_DREAM_COLLEGE = "/api/superadmin/delete-dream-college";

// app version
export const POST_FETCH_APP_VERSIONS = "/api/admin/get-app-versions";
export const POST_FETCH_APP_VERSION = "/api/admin/get-app-version";
export const POST_CREATE_APP_VERSION = "/api/admin/create-app-version";
export const POST_UPDATE_APP_VERSION = "/api/admin/update-app-version";
export const POST_DELETE_APP_VERSION = "/api/admin/delete-app-version";

// Language
export const POST_FETCH_LANGUAGES = "/api/admin/get-languages";
export const POST_FETCH_LANGUAGE = "/api/admin/get-language";
export const POST_CREATE_LANGUAGE = "/api/admin/create-language";
export const POST_UPDATE_LANGUAGE = "/api/admin/update-language";
export const POST_DELETE_LANGUAGE = "/api/admin/delete-language";

// cATEGORY
export const POST_FETCH_CATEGORIES = "/api/admin/get-categories";
export const POST_FETCH_CATEGORY = "/api/admin/get-category";
export const POST_CREATE_CATEGORY = "/api/admin/create-category";
export const POST_UPDATE_CATEGORY = "/api/admin/update-category";
export const POST_DELETE_CATEGORY = "/api/admin/delete-category";

// Approval Request
export const POST_FETCH_APPROVAL_REQUESTS = "/api/superadmin/get-approval-requests";
export const POST_FETCH_APPROVAL_REQUEST = "/api/superadmin/get-approval-request";
export const POST_UPDATE_APPROVAL_REQUEST = "/api/superadmin/update-approval-request";

// STUDENT
export const POST_FETCH_STUDENTS = "/api/superadmin/get-students"
export const POST_FETCH_STUDENT = "/api/superadmin/get-student"
export const POST_CREATE_STUDENT = "/api/superadmin/create-student"
export const POST_UPDATE_STUDENT = "/api/superadmin/update-student"
export const POST_DELETE_STUDENT = "/api/superadmin/delete-student"

// CONSULTANT
export const POST_FETCH_CONSULTANTS = "/api/superadmin/get-consultants"
export const POST_FETCH_CONSULTANT = "/api/superadmin/get-consultant"
export const POST_CREATE_CONSULTANT = "/api/superadmin/create-consultant"
export const POST_UPDATE_CONSULTANT = "/api/superadmin/update-consultant"
export const POST_DELETE_CONSULTANT = "/api/superadmin/delete-consultant"

// ALL SESSIONS
export const POST_FETCH_SESSIONS = "/api/admin/get-sessions"
export const POST_FETCH_SESSION = "/api/admin/get-session"

// DASHBOARD COUNT
export const POST_FETCH_DASHBOARD_COUNT = "/api/admin/get-dashboard-count"

// Activity Type
export const POST_FETCH_ACTIVITY_TYPES = "/api/admin/get-activity-types";
export const POST_FETCH_ACTIVITY_TYPE = "/api/admin/get-activity-type";
export const POST_UPDATE_ACTIVITY_TYPE = "/api/admin/update-activity-type";
export const POST_DELETE_ACTIVITY_TYPE = "/api/admin/delete-activity-type";

// TIME ZONE
export const POST_FETCH_TIMEZONES = "/api/admin/get-timezones";
export const POST_FETCH_TIMEZONE = "/api/admin/get-timezone";
export const POST_CREATE_TIMEZONE = "/api/admin/create-timezone"
export const POST_UPDATE_TIMEZONE = "/api/admin/update-timezone";
export const POST_DELETE_TIMEZONE = "/api/admin/delete-timezone";

// SESSION TYPES
export const POST_FETCH_SESSION_TYPES = "/api/admin/get-session-types";
export const POST_FETCH_SESSION_TYPE = "/api/admin/get-session-type";
export const POST_UPDATE_SESSION_TYPE = "/api/admin/update-session-type";

// STORY
export const POST_FETCH_ALL_STORIES = "/api/admin/get-stories";
export const POST_FETCH_STORY = "/api/admin/get-story";
export const POST_CREATE_STORY = "/api/admin/create-story"
export const POST_UPDATE_STORY = "/api/admin/update-story";
export const POST_DELETE_STORY = "/api/admin/delete-story";
export const POST_UPDATE_BANNER_IMAGE = "/api/admin/upload-banner-image";
