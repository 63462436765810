import React, { useMemo, useEffect, useState } from "react";

import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    Label,
    Input,
    FormFeedback, 
    Button,
    Container,
} from "reactstrap";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { withTranslation } from "react-i18next";
import { CMSApi } from "apis/CMSApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom"

const EditCMS = (props) => {
    const history = useHistory();
    const cms_id = props.match.params.cms_id;
    const [fetchCMSPage, setFetchCMSPage] = useState([]);
    const [contentEn, setContentEn] = useState(() => EditorState.createEmpty());
    const [convertedContentEn, setConvertedContentEn] = useState(null);

    //meta title
    document.title = "CMS"

    useEffect(() => {
        getCMSById()
    }, []);

    const getCMSById = () => {
        CMSApi.getCMSById({ "cms_id": cms_id })
            .then(res => {
                setFetchCMSPage(res.data.data)
                console.log()
                setContentEn(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(res?.data?.data?.content)
                    )
                ))
            }).catch(err => {
                console.log(err)
            })
    }

    const handleEditorContentEnChange = (state) => {
        setContentEn(state);
        convertContentEnTextToHTML();
    }
    const convertContentEnTextToHTML = () => {
        let currentContentEnTextAsHTML = convertToHTML(contentEn.getCurrentContent());
        setConvertedContentEn(currentContentEnTextAsHTML);
    }

    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: fetchCMSPage ? fetchCMSPage.name : "",
            content: fetchCMSPage ? fetchCMSPage.content : "",
        },

        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                props.t("Required-Message")
            ),
            content: Yup.string().required(
                props.t("Required-Message")
            ),

        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('name', values.name);
            data.append('content', convertedContentEn ? convertedContentEn : values.content);
            data.append('cms_id', cms_id);
            CMSApi.updateCMS(data)
                .then(res => {
                    if (res.data.success) {
                        Swal.fire({
                            text: res.data.message,
                            icon: 'success',
                            imageAlt: 'success image',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push('/cms');
                            }
                        });
                    } else {
                        toastr.error(res.message, '');
                    }
                }).catch(function (error) {
                    if (error?.response?.data?.message) {
                        toastr.error(error?.response?.data?.message, '');
                    }
                    else {
                        toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                    }
                });

        }
    });

    return (
        <React.Fragment>
            <div className="cms">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Setting")} breadcrumbItem={props.t("Edit") + " " + props.t("Page_Content")} />
                    <Row>
                        <Col className="col-12">

                            <Button style={{ float: "right", marginBottom: "20px", }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>

                        </Col>
                        <Col>

                            <Card>
                                <CardBody>
                                    <Form method="post" className="wysiwyg-custom" onSubmit={(e) => {
                                        e.preventDefault();
                                        validationType.handleSubmit();
                                        return false;
                                    }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name")}</Label>
                                                <Input
                                                    name="name"
                                                    placeholder={props.t("Name")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name || ""}
                                                    invalid={
                                                        validationType.touched.name && validationType.errors.name ? true : false
                                                    }
                                                />
                                                {validationType.touched.name && validationType.errors.name ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Label className="form-label">{props.t("Content")}</Label>
                                        <Editor
                                            name="content"
                                            editorState={contentEn}
                                            onEditorStateChange={handleEditorContentEnChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                        />
                                        <Button color="primary" type="submit">
                                            {props.t("Update")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(EditCMS);
