import React, { useMemo, useEffect, useState } from "react";

import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    Label,
    Input,
    FormFeedback, 
    Button,
    Container,
} from "reactstrap";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { EditorState, ContentState, convertFromHTML } from 'draft-js'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withTranslation } from "react-i18next";
import { StoryApi } from "apis/StoryApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom"

const EditStory = (props) => {
    const history = useHistory();
    const story_id = props.match.params.story_id;
    const [imagefile, setImageFile] = useState([]);
    const [story, setStory] = useState([]);
    const [contentEn, setContentEn] = useState(() => EditorState.createEmpty());
    const [convertedContentEn, setConvertedContentEn] = useState(null);

    //meta title
    document.title = "Edit Story"

    useEffect(() => {
        getStory()
    }, []);

    const getStory = () => {
        StoryApi.getStory({ "story_id": story_id })
            .then(res => {
                setStory(res?.data?.data)
                setContentEn(EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(res?.data?.data?.detail_html)
                    )
                ))
            }).catch(err => {
                console.log(err)
            })
    }

    const handleEditorContentEnChange = (state) => {
        setContentEn(state);
        convertContentEnTextToHTML();
    }
    const convertContentEnTextToHTML = () => {
        let currentContentEnTextAsHTML = convertToHTML(contentEn.getCurrentContent());
        setConvertedContentEn(currentContentEnTextAsHTML);
    }

    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            banner_image: story ? story.banner_image : "",
            title: story ? story.title : "",
            subtitle: story ? story.subtitle : "",
            detail_normal: story ? story.detail_normal : "",
            detail_html: story ? story.detail_html : "",

        },

        validationSchema: Yup.object().shape({
            banner_image: Yup.string().required(
                props.t("Required-Message")
            ),
            title: Yup.string().required(
                props.t("Required-Message")
            ),
            subtitle: Yup.string().required(
                props.t("Required-Message")
            ),
            detail_normal: Yup.string().required(
                props.t("Required-Message")
            ),
            detail_html: Yup.string().required(
                props.t("Required-Message")
            ),

        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('banner_image', imagefile);
            // data.append('banner_image', values.banner_image);
            data.append('title', values.title);
            data.append('subtitle', values.subtitle);
            data.append('detail_normal', values.detail_normal);
            data.append('detail_html', convertedContentEn);
                StoryApi.updateStory(data)
                .then(res => {
                    if (res.data.success) {
                        Swal.fire({
                            text: res.data.message,
                            icon: 'success',
                            imageAlt: 'success image',
                        }).then((result) => {
                            if (result?.isConfirmed) {
                                history.push('/stories');
                                setImageFile()
                            }
                        });
                    } else {
                        toastr.error(res?.data?.message, '');
                    }
                }).catch(function (error) {
                    if (error?.response?.data?.message) {
                        toastr.error(error?.response?.data?.message, '');
                    }
                    else {
                        toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                    }
                });
            // }
        }
    });

    return (
        <React.Fragment>
            <div className="story">
                <Container fluid={true}>
                    <Breadcrumbs title={props.t("Stories")} breadcrumbItem={props.t("Edit") + " " + props.t("Story")} />
                    <Row>
                        <Col className="col-12">

                            <Button style={{ float: "right", marginBottom: "20px", }}
                                color="primary"
                                className="waves-effect waves-light  pull-right"
                                onClick={() => history.goBack()}
                            >
                                {props.t("Back")}
                            </Button>

                        </Col>
                        <Col>

                            <Card>
                                <CardBody>
                                    <Form method="post" className="wysiwyg-custom" onSubmit={(e) => {
                                        e.preventDefault();
                                        validationType.handleSubmit();
                                        return false;
                                    }}>

                                    {/* VIEW EXISTING BANNER IMAGE */}
                                        <Col>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Story Banner")}</Label>
                                                    <div>
                                                        {/* <img src= {`./assets/images/banner/${session.banner_image}`} */}
                                                            <img src= {`http://localhost:8003/storage/images/admin/${story.banner_image}`}
                                                                alt={"banner_image"}
                                                                height="200"
                                                                width="500"
                                                                // align="center"
                                                            />
                                                    </div>
                                            </div>
                                        </Col>

                                        {/* UPLOAD BANNER IMAGE */}
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="validationCustom01">
                                                    {props.t("Upload Banner Image")}
                                                </Label>
                                                <Input
                                                    name="banner_image"
                                                    type="file"
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        setImageFile(e.target.files[0]);
                                                        validationType.setFieldValue('banner_image', e.target.files[0]);
                                                    }}
                                                    invalid={
                                                        validationType.touched.banner_image &&
                                                            validationType.errors.banner_image
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validationType.touched.banner_image &&
                                                    validationType.errors.banner_image ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.banner_image}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {/* TITLE */}
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Title")}</Label>
                                                <Input
                                                    name="title"
                                                    placeholder={props.t("Title")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.title || ""}
                                                    invalid={
                                                        validationType.touched.title && validationType.errors.title ? true : false
                                                    }
                                                />
                                                {validationType.touched.title && validationType.errors.title ? (
                                                    <FormFeedback type="invalid">{validationType.errors.title}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {/* SUBTITLE */}
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("SubTitle")}</Label>
                                                <Input
                                                    name="subtitle"
                                                    placeholder={props.t("SubTitle")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.subtitle || ""}
                                                    invalid={
                                                        validationType.touched.subtitle && validationType.errors.subtitle ? true : false
                                                    }
                                                />
                                                {validationType.touched.subtitle && validationType.errors.subtitle ? (
                                                    <FormFeedback type="invalid">{validationType.errors.subtitle}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {/* DETAILS NORMAL */}
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Detail (Normal)")}</Label>
                                                <Input
                                                    name="detail_normal"
                                                    placeholder={props.t("Detail (Normal)")}
                                                    type="textarea"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.detail_normal || ""}
                                                    invalid={
                                                        validationType.touched.detail_normal && validationType.errors.detail_normal ? true : false
                                                    }
                                                />
                                                {validationType.touched.detail_normal && validationType.errors.detail_normal ? (
                                                    <FormFeedback type="invalid">{validationType.errors.detail_normal}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {/* DETAILS HTML */}
                                        <Label className="form-label">{props.t("Detail (HTML)")}</Label>
                                        <Editor
                                            name="detail_html"
                                            editorState={contentEn}
                                            onEditorStateChange={handleEditorContentEnChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                        />
                                        <Button color="primary" type="submit">
                                            {props.t("Submit")}
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(EditStory);
