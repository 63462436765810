// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { SessionApi } from "../../../apis/SessionApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import config from "../../../config/config";
import constants from "../../../config/constants";

import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;


import {
    Input,
    Row,
    Col
} from "reactstrap";

function AllSessions(props) {

  const history = useHistory();

  const[startDate, setStartDate] = useState("")
  const[endDate, setEndDate] = useState("")

  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    filter: {
      status: "",
      startDate: "",
      endDate: ""
    },
    limit: config.LIMIT,
  });

  useEffect(() => {
    getAllSessions(activeData);
  }, []);

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.session_id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Name"),
      selector: row => row.name,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Title"),
      selector: row => row.title,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Date"),
      selector: row => moment(row.date).format('D MMMM, YYYY'),
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Status"),
      selector: row => row.status,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    }
  ];



  const getAllSessions = (data) => {
    setLoading(true);
    SessionApi.getSessions(data)
      .then((res) => {
        setActiveData({
          activePage: activeData.activePage,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          filter: activeData.filter,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        
        res?.data?.data?.rows?.forEach((v, i) => {
            sr_no = sr_no + 1;
          data[i] = {
            session_id: sr_no,
            name: v.userName,
            title: v.title,
            date: v.start_date_time,
            status: v.status,
            action: (
              <>
              <Link to={`/session-details/${v.id}`}><i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} />{" "}</Link>
              </>
            ),
          };
        });
        setSessions(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (v) => {
    setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
    const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
    getAllSessions(data)
  }

  const handleRowChange = (v) => {
    setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
    const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
    getAllSessions(data)
  }

  const handleChangeSearch = () => {
    const data = { ...activeData, page: 1, search: activeData.search }
    getAllSessions(data)
     }
  const onSearch = (e) => {
       setActiveData({ ...activeData, search: e.target.value })
  }

// FILTER

  const handleFilter = (e) => {
    const filterValue = e.value
    const data = { ...activeData, page: 1, filter: {...activeData.filter, status:filterValue }}
    getAllSessions(data)
     }

  const handleChangeDate = (e) => {
// console.log(startDate)
// console.log(endDate)
    const data = {...activeData, page: 1,  filter: {...activeData.filter, startDate: startDate, endDate: endDate}}
       setActiveData(data)
       getAllSessions(data)
  }

  //meta title  
  document.title = props.t("All Sessions") + ' | ' + props.t("LearnLive");

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={props.t("Sessions")} breadcrumbItem={props.t("All Sessions")} />
      </div>
      
    <div className="container-fluid mb-3">
    <Row className="mb-3">
      <Col sm={4}>
        <div className="d-flex">
        {/* style={ {marginLeft: "2px"}} */}
          <Input  style={ {marginRight: "20px"}}  onChange={onSearch} name="search" placeholder={props.t("Search")} />
          <button onClick={handleChangeSearch} className="btn btn-primary">{props.t("Search")}</button>
        </div>
      </Col>

      {/* FILTER */}
<Col sm={4}>
        <div>
                <Select
                  className={"text-dark"}
                  placeholder={"filter by status"}
                  styles={{
                    control: styles => ({
                      ...styles,
                    }),
                  }}
                  onChange={handleFilter}
                  name="sessionstatus"
                  options={Object.keys(constants.SESSION_STATUS).map(
                    value => ({
                      value: `${value}`,
                      label: `${constants.SESSION_STATUS[value]}`
                    })
                  )}                                                       
                />
          </div>
      </Col>

  </Row>

  <Row>
        {/* DATE FILTER */}
      <Col sm={3}>
  <div>
    <Input
      name="start_date"
      id="start_date"
      type="date"
      onChange={(e)=>{
        setStartDate(e.target.value)
      }}
    />
  </div>
</Col>

<Col sm={3}>
  <div>
    <Input
      name="end_date"
      id="end_date"
      type="date"
      onChange={(e)=>{
        setEndDate(e.target.value)
      }}
    />
  </div>
</Col>
<Col sm={2}>
<button onClick={handleChangeDate} className="btn btn-primary">{props.t("Submit")}</button>
</Col>
      </Row>
    </div>

      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={sessions}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={value => handleRowChange(value)}
          onChangePage={value => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  );
}
AllSessions.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(AllSessions);