import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ProfileApi } from "../../../apis/ProfileApi";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { getUserID, getName, getEmail, getProfilePhoto } from "../../../Token";

// users
import profileImage from "../../../assets/images/profile.png";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Admin");

  useEffect(() => {

    setusername(getName);
    getProfileDetail();
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(getName);
        // console.log(obj)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {

        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
        // console.log(obj)

      }
    }
  }, [props.success]);

  const [adminDetail, setAdminDetail] = useState({});

  const getProfileDetail = () => {
    ProfileApi.getProfileDetail()
      .then((res) => {
        if (res.data.success) {
          setAdminDetail(res.data.data);
        } else {
          setAdminDetail("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileImage}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}

          <Link to="/update-profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("Update_Profile")}</span>
          </Link>

          <Link to="/change-password" className="dropdown-item">
            <i className="bx bx-layer-plus font-size-16 align-middle me-1" />
            <span>{props.t("Change_Password")}</span>
          </Link>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
