import React from "react"
import { Container, Row, Col } from "reactstrap"
import { withTranslation } from "react-i18next";

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © LearnLive.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                {props.t("Design_By")}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default  withTranslation()(Footer)