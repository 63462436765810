import React, { useEffect, useState } from "react";
import "toastr/build/toastr.min.css";
import PropTypes from 'prop-types'
import {
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Row,
    Col,
    Button,
    Container,
  } from "reactstrap";
  import config from "config/config"
  import { useFormik } from "formik"
  import * as Yup from "yup"
import { SessionApi } from "../../../apis/SessionApi";
import { useHistory } from "react-router-dom"
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import moment from "moment";

const SessionDetails = props => {
  const history = useHistory();
  const session_id = props.match.params.session_id;
  const [session, setSession] = useState('');

  useEffect(() => {getSessionById();}, [])

  const getSessionById = () => {
    SessionApi.getSession({session_id:session_id})
      .then(res => {
        setSession(res.data.data)
      }).catch(err => {
        console.log(err)
      })
  }

  // Form validation 
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name : session ? session?.userName : "",
        title: session ? session.title : "",
        date: session ? moment(session.start_date_time).format('D MMMM, YYYY')  : "",
        start_time: session ? moment(session.start_date_time, ["HH:mm:ss"]).format("hh:mm a") : "",
        end_time: session ? moment(session.end_date_time, ["HH:mm:ss"]).format("hh:mm a") : "",
        timezone: session ? session.timeZoneName : "",
        participate_limit: session ? session.participate_limit : "",
        meeting_link: session ? session.meeting_link : "",
        about: session ? session.about : "",
        session_type: session ? session.sessionTypeName : "",
        visibility: session ? session.visibility : "",
        status: session ? session.status : "",
        banner_image: session ? session?.banner_image: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(
        props.t("Required-Message")
      ),
      title: Yup.string().required(
        props.t("Required-Message")
      ),
      date: Yup.string().required(
        props.t("Required-Message")
      ),
      start_time: Yup.string().required(
        props.t("Required-Message")
      ),
      end_time: Yup.string().required(
        props.t("Required-Message")
      ),
      timezone: Yup.string().required(
        props.t("Required-Message")
      ),
      participate_limit: Yup.string().required(
        props.t("Required-Message")
      ),
      meeting_link: Yup.string().required(
        props.t("Required-Message")
      ),
      about: Yup.string().required(
        props.t("Required-Message")
      ),
      session_type: Yup.string().required(
        props.t("Required-Message")
      ),
      visibility: Yup.string().required(
        props.t("Required-Message")
      ),
      status: Yup.string().required(
        props.t("Required-Message")
      ),
    }),
  }); 

  document.title = props.t("Session Details") + ' | ' + props.t("LearnLive");

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title={props.t("Session Details")} breadcrumbItem={props.t("Session") + " " + props.t("Details")} />
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Button style={{ float: "right", marginBottom: "20px", }} color="primary" className="waves-effect waves-light  pull-right" onClick={() => history.goBack()}>
                {props.t("Back")}
              </Button>
            </Col>
            
            <Card>
                <CardBody >
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationType.handleSubmit();
                      return false;
                    }}>
                    
                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Name")}</Label>
                        <Input
                          name="name"
                          placeholder="name"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.name || ""}
                          invalid={
                            validationType.touched.name && validationType.errors.name ? true : false
                          }
                        />
                        {validationType.touched.name && validationType.errors.name ? (
                          <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Title")}</Label>
                        <Input
                          name="title"
                          placeholder="title"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.title || ""}
                          invalid={
                            validationType.touched.title && validationType.errors.title ? true : false
                          }
                        />
                        {validationType.touched.title && validationType.errors.title ? (
                          <FormFeedback type="invalid">{validationType.errors.title}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>

                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Date")}</Label>
                        <Input
                          name="date"
                          placeholder="date"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.date || ""}
                          invalid={
                            validationType.touched.date && validationType.errors.date ? true : false
                          }
                        />
                        {validationType.touched.date && validationType.errors.date ? (
                          <FormFeedback type="invalid">{validationType.errors.date}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Time Zone")}</Label>
                        <Input
                          name="timezone"
                          placeholder="timezone"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.timezone || ""}
                          invalid={
                            validationType.touched.timezone && validationType.errors.timezone ? true : false
                          }
                        />
                        {validationType.touched.timezone && validationType.errors.timezone ? (
                          <FormFeedback type="invalid">{validationType.errors.timezone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>

                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Start Time")}</Label>
                        <Input
                          name="start_time"
                          placeholder="start_time"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.start_time || ""}
                          invalid={
                            validationType.touched.start_time && validationType.errors.start_time ? true : false
                          }
                        />
                        {validationType.touched.start_time && validationType.errors.start_time ? (
                          <FormFeedback type="invalid">{validationType.errors.start_time}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                   
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("End Time")}</Label>
                        <Input
                          name="end_time"
                          placeholder="end_time"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.end_time || ""}
                          invalid={
                            validationType.touched.end_time && validationType.errors.end_time ? true : false
                          }
                        />
                        {validationType.touched.end_time && validationType.errors.end_time ? (
                          <FormFeedback type="invalid">{validationType.errors.end_time}</FormFeedback>
                        ) : null}
                      </div>
                      </Col>
                      </Row>

                      <Row>
                      <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Participant Limit")}</Label>
                        <Input
                          name="participate_limit"
                          placeholder="participate_limit"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.participate_limit || ""}
                          invalid={
                            validationType.touched.participate_limit && validationType.errors.participate_limit ? true : false
                          }
                        />
                        {validationType.touched.participate_limit && validationType.errors.participate_limit ? (
                          <FormFeedback type="invalid">{validationType.errors.participate_limit}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                   
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Meeting Link")}</Label>
                        <Input
                          name="meeting_link"
                          placeholder="meeting_link"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.meeting_link || ""}
                          invalid={
                            validationType.touched.meeting_link && validationType.errors.meeting_link ? true : false
                          }
                        />
                        {validationType.touched.meeting_link && validationType.errors.meeting_link ? (
                          <FormFeedback type="invalid">{validationType.errors.meeting_link}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>

                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("About")}</Label>
                        <Input
                          name="about"
                          placeholder="about"
                          type="textarea"
                          readOnly="readOnly"
                          disabled={true}
                          position={"fixed"}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.about || ""}
                          invalid={
                            validationType.touched.about && validationType.errors.about ? true : false
                          }
                        />
                        {validationType.touched.about && validationType.errors.about ? (
                          <FormFeedback type="invalid">{validationType.errors.about}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                   
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Status")}</Label>
                        <Input
                          name="status"
                          placeholder="status"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.status || ""}
                          invalid={
                            validationType.touched.status && validationType.errors.status ? true : false
                          }
                        />
                        {validationType.touched.status && validationType.errors.status ? (
                          <FormFeedback type="invalid">{validationType.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>

                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Session Type Name")}</Label>
                        <Input
                          name="session_type"
                          placeholder="session_type"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.session_type || ""}
                          invalid={
                            validationType.touched.session_type && validationType.errors.session_type ? true : false
                          }
                        />
                        {validationType.touched.session_type && validationType.errors.session_type ? (
                          <FormFeedback type="invalid">{validationType.errors.session_type}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Visibility")}</Label>
                        <Input
                          name="visibility"
                          placeholder="visibility"
                          type="text"
                          readOnly="readOnly"
                          disabled={true}
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.visibility || ""}
                          invalid={
                            validationType.touched.visibility && validationType.errors.visibility ? true : false
                          }
                        />
                        {validationType.touched.visibility && validationType.errors.visibility ? (
                          <FormFeedback type="invalid">{validationType.errors.visibility}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
          </Row>
        </Container>
        
                  <Col>
                    <div className="mb-3">
                          <Label className="form-label">{props.t("Session Banner")}</Label>
                      <div>
                          {/* <img src= {`./assets/images/banner/${session.banner_image}`} */}
                          <img src= {`http://localhost:8002/storage/images/${session.banner_image}`}
                          alt={"banner_image"}
                          // alt={"banner_image" || `${session.banner_image}`}
                          height="200"
                          width="500"
                          // align="center"
                          />
                      </div>
                    </div>
                  </Col>
      </div>
    </React.Fragment>
  );
}



  SessionDetails.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
  }

export default withTranslation()(SessionDetails);