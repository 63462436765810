import axios from "axios";
import config from "../config/config";
import * as url from "../helpers/url_helper";
// import {publicIp, publicIpv4, publicIpv6} from 'public-ip';

export const AuthAPI = {
    login: async (data) => {
        const axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_ADMIN_LOGIN}`,
            headers: {
                'Content-Type': 'application/json',
                //'ip' : await publicIpv4()
            },
            data: data
        };
        return await axios(axiosConfig);
    },
    forgotPassword: async (data) => {
        return await axios.post(`${config.API_URL}/api/admin/forgot-password`, data);
    },
    resetPassword: async (data) => {
        return await axios.post(`${config.API_URL}/api/admin/reset-password`, data);
    }
}