import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";

const token = getToken();
export const ActivityTypeApi = {
    getActivityTypes: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ACTIVITY_TYPES}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
 
    getActivityType: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ACTIVITY_TYPE}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updateActivityType: async (data) => {
        var axiosConfig = {
            method: 'put',
            url: `${config.API_URL}${url.POST_UPDATE_ACTIVITY_TYPE}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteActivityType: async (data) => {
        var axiosConfig = {
            method: 'delete',
            url: `${config.API_URL}${url.POST_DELETE_ACTIVITY_TYPE}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    }
}