// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CMSApi } from "apis/CMSApi";
import { PageContentApi } from "apis/PageContentApi";
import TableContainer from "components/Common/TableContainer";
import { withTranslation } from "react-i18next";

import config from "config/config";
import Swal from "sweetalert2";

function PageContent(props) {
    const [loading, setLoading] = useState(false);
    const [pagecontentList, setPageContentList] = useState([]);
    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT,
    });
    useEffect(() => {
        getAllPageContent(activeData);
    }, []);

    const getAllPageContent = (data) => {
        // setLoading(true);
        PageContentApi.getAllPageContents(data)
            .then((res) => {
                setActiveData({
                    activePage: activeData.page,
                    totalPage: res.data.data.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                res.data.data.rows.forEach((v, i) => {
                    data[i] = {
                        page_content_id: v.id,
                        name: v.name,
                        page_type: v.type,
                        action: (
                            <>

                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-page-content/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>

                                <a
                                    target="_blank" rel="noreferrer"
                                    style={{ marginRight: "15px", color: "#7b8190" }}
                                    href={`${config.API_URL}/api/common/pages/cms-page?page_type=${v.type}`}
                                >
                                    <i className="mdi mdi-eye-outline font-size-18" style={{ color: "#7b8190" }} id="edittooltip" />
                                </a>
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setPageContentList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.page_content_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name"),
            selector: row => row.name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Type"),
            selector: row => row.page_type,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];
    const handleChange = (v) => {
        setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllPageContent(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ activePage: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { activePage: page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllPageContent(data)
    }

    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let PageContentId = { "page_content_id": id };
                PageContentApi.deletePageContent(PageContentId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        getAllPageContent(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    document.title = "Page Content | LearnLive";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Page Contents")} />
            </div>



            <div className="container-fluid">
                <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={pagecontentList}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                />
            </div>
        </div>
    );
}
PageContent.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default withTranslation()(PageContent);